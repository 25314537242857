import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from 'components/SEO'
import styles from './404.module.scss'

export const query = graphql`
  query PageNotFoundQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    sadFile: file(
      relativeDirectory: {regex: "/"},
      name: {eq: "sad-file"}
    ) {
      childImageSharp {
        fluid(maxHeight: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

const PageNotFound = ({ data }) => {
  const { banner, sadFile } = data
  
  return (
    <>
    <SEO
      title={`Page not found | SloppyDeep`}
      description={`Oops! The page you are looking for does not exist, please go back to the homepage or look for a new sexy camgirl`}
      keywords={``}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/404/`}
      robots='noindex, nofollow'
    />
    <div className={`${styles.pageNotFoundScreen} screen-container`}>
      <h1 className={styles.title}>404</h1>
      <p className={styles.details}>It looks like you're lost...</p>
      <Link to={'/'} className={styles.homeButton}>Back to Home</Link>
    </div>
    </>
  )
}

export default PageNotFound
